import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import animationData from "./animationData.json"
import animationDataMobile from "./animationDataMobile.json"
import lottie from "lottie-web"

export default ({ data }) => {
  useEffect(() => {
    document.body.className = "onIntro"

    lottie.loadAnimation({
      container: document.getElementById("lottie"), // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animationData,
    })

    document.getElementById("lottie").className = "animation"

    lottie.loadAnimation({
      container: document.getElementById("lottieMobile"), // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animationDataMobile,
    })

    document.getElementById("lottieMobile").className = "animation"

    return () => {
      document.body.className = ""
    }
  }, [])

  return (
    <Layout>
      <SEO title="" keywords={[`soso`, `sosolin`]} />
      <div className="intro">
        <div id="lottie" />
        <div id="lottieMobile" />
        <div className="bigText">
          <p>
            I’m soso and a graphic designer based in Shenzhen. I make cool
            websites and art, check them out :)
          </p>
        </div>
      </div>
    </Layout>
  )
}
